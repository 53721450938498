import { FC } from 'react'
import styled from 'styled-components'

import { useQuiz } from '../../../context/QuizContext'
import { device } from '../../../styles/BreakPoints'
import { HighlightedText } from '../../../styles/Global'
import { convertSeconds } from '../../../utils/helpers'
import { Result } from '../../../types'
import DataConsumer from '../../../assets/icons/keytrade/_Data Consumer-02.svg'
import DataAnalyst from '../../../assets/icons/keytrade/_Data Analyst.svg'
import DataPowerUser from '../../../assets/icons/keytrade/_Data Power user.svg'
import DataExpert from '../../../assets/icons/keytrade/_Data Expert.svg'
import DataOwner from '../../../assets/icons/keytrade/_Data Owner.svg'
import DataSteward from '../../../assets/icons/keytrade/_Data Steward.svg'
import DataCustodian from '../../../assets/icons/keytrade/_Data Custodian.svg'

const roleDetails: Record<string, { description: string; pictureURL: string }> = {
  'Data & AI Consumer': {
    description:
      'A person who uses insights (reports, dashboards, data extracts, …) to support decision making in his/her day-to-day job. In other words, anyone in the organization.',
    pictureURL: DataConsumer,
  },
  'Data & AI Analyst': {
    description:
      'A person who extracts, transforms and combines data into useful information & actionable insights (reports, dashboards, data extracts, …) for the business department they work in.',
    pictureURL: DataAnalyst,
  },
  'Data & AI Power User': {
    description:
      'A data subject matter expert who supports other people in using data, and who maintains and supports data products for his/her business department. A typical single point of contact role in a business department who can help data consumers and data analysts in that business department with all data & AI related questions.',
    pictureURL: DataPowerUser,
  },
  'Data & AI Expert': {
    description:
      'A person that is skilled in building, in close collaboration with IT, industrialized data & AI products that provide useful information and actionable insights at scale.This person typically works in a data or IT team.',
    pictureURL: DataExpert,
  },
  'Data & AI Owner': {
    description:
      'A person that is accountable for ensuring the quality, accessibility, usage, integrity and protection of data & AI for a specific Keytrade bank data & AI domain.',
    pictureURL: DataOwner,
  },
  'Data & AI Steward': {
    description:
      'A person responsible for carrying out the necessary actions to guarantee the quality, integrity and protection of data & AI within his/her data & AI domain.',
    pictureURL: DataSteward,
  },
  'Data & AI Custodian': {
    description:
      'A person responsible for the technical aspects of data & AI maintenance, quality, integrity and protection. This person makes sure that the IT features are implemented correctly and in time.',
    pictureURL: DataCustodian,
  },
}

const ResultOverviewStyle = styled.div`
  text-align: center;
  margin-bottom: 20px;
  @media ${device.md} {
    margin-bottom: 30px;
  }
  img {
    display: block; /* Ensure the image is treated as a block element */
    margin: 0 auto; /* Center the image horizontally */
    max-width: 100%; /* Ensure the image does not exceed its container width */
  }
  p {
    margin-top: 15px;
    font-weight: 500;
    font-size: 18px;
  }
  h1 {
    margin-bottom: 20px;
  }
`

interface ResultOverviewProps {
  result: Result[]
  roleScores: Record<string, number>
}

const ResultOverview: FC<ResultOverviewProps> = ({ result, roleScores }) => {
  const { quizDetails, endTime } = useQuiz()

  const totalQuestionAttempted = result.length

  const obtainedScore = result
    .filter((item) => item.isMatch && typeof item.score === 'number')
    .reduce((accumulator, currentValue) => accumulator + (currentValue.score || 0), 0)

  // Passed if 60 or more than 60% marks
  const calculateStatus =
    (obtainedScore / quizDetails.totalScore) * 100 >= 60 ? 'Passed' : 'Failed'

  // Calculate the role with the highest score
  const highestScoreRole = Object.keys(roleScores).reduce((a, b) =>
    roleScores[a] > roleScores[b] ? a : b
  )

  const { description, pictureURL } = roleDetails[highestScoreRole]

  return (
    <ResultOverviewStyle>
      <h1>{highestScoreRole}</h1>
      <img src={pictureURL} alt="Role Picture" width={260} />
      <p>
        Congratulations, the role which is closest to your preference is:{' '}
        <HighlightedText>{highestScoreRole}</HighlightedText>
      </p>

      <p>Description: {description}</p>
    </ResultOverviewStyle>
  )
}

export default ResultOverview

/*
<p>
        Time Spent:<HighlightedText> {convertSeconds(endTime)} </HighlightedText>
      </p>
      <p>
        Status:<HighlightedText> {calculateStatus}</HighlightedText>
      </p>
*/
