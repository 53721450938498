// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const python: Topic = {
  topic: 'Python',
  level: 'Advanced',
  totalQuestions: 14, // Remember to update this count if you add/remove questions
  totalScore: 125, // Adjust the total score based on your scoring system
  totalTime: 360,
  roles: {
    Developer: 0,
    Architect: 0,
    Analyst: 0,
  },
  questions: [
    {
      question: 'Which of the following are Python data types? (Select all that apply)',
      choices: ['List', 'Tuple', 'Method', 'Slice'],
      type: 'MAQs',
      correctAnswers: ['List', 'Tuple'],
      score: 10,
      answerRoles: {
        List: 'Developer',
        Tuple: 'Developer',
        Method: 'Architect',
        Slice: 'Analyst',
      },
    },
    {
      question: 'What is the output of print(2 ** 3)?',
      choices: ['8', '6', '9', 'None of the above'],
      type: 'MCQs',
      correctAnswers: ['8'],
      score: 10,
      answerRoles: {
        '8': 'Developer',
        '6': 'Analyst',
        '9': 'Architect',
        'None of the above': 'Analyst',
      },
    },
    {
      question: 'Which keyword is used for function declaration in Python?',
      choices: ['def', 'function', 'method', 'declare'],
      type: 'MCQs',
      correctAnswers: ['def'],
      score: 10,
      answerRoles: {
        def: 'Developer',
        function: 'Architect',
        method: 'Analyst',
        declare: 'Analyst',
      },
    },
    {
      question: 'How do you declare a comment in Python?',
      choices: [
        '# This is a comment',
        '// This is a comment',
        '/* This is a comment */',
        '-- This is a comment',
      ],
      type: 'MCQs',
      correctAnswers: ['# This is a comment'],
      score: 10,
      answerRoles: {
        '# This is a comment': 'Developer',
        '// This is a comment': 'Analyst',
        '/* This is a comment */': 'Architect',
        '-- This is a comment': 'Analyst',
      },
    },
    {
      question: 'What does the len() function in Python return?',
      choices: [
        'The length of an object',
        'The size of an object in bytes',
        'The value of an object',
        'None of the above',
      ],
      type: 'MCQs',
      correctAnswers: ['The length of an object'],
      score: 10,
      answerRoles: {
        'The length of an object': 'Developer',
        'The size of an object in bytes': 'Analyst',
        'The value of an object': 'Architect',
        'None of the above': 'Developer',
      },
    },
    {
      question: 'Which of the following is a mutable object in Python?',
      choices: ['String', 'Tuple', 'List', 'All of the above'],
      type: 'MCQs',
      correctAnswers: ['List'],
      score: 10,
      answerRoles: {
        String: 'Architect',
        Tuple: 'Analyst',
        List: 'Developer',
        'All of the above': 'Analyst',
      },
    },
    {
      question: 'Which operator is used in Python for floor division?',
      choices: ['/', '//', '%', '**'],
      type: 'MCQs',
      correctAnswers: ['//'],
      score: 10,
      answerRoles: {
        '/': 'Analyst',
        '//': 'Developer',
        '%': 'Architect',
        '**': 'Developer',
      },
    },
    {
      question: 'Python is ___ typed language.',
      choices: ['Statically', 'Dynamically'],
      type: 'boolean',
      correctAnswers: ['Dynamically'],
      score: 10,
      answerRoles: {
        Statically: 'Architect',
        Dynamically: 'Developer',
      },
    },
  ],
}
