// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const dataRole: Topic = {
  topic: 'DataRole',
  level: 'Mixed',
  totalQuestions: 10,
  totalScore: 500,
  totalTime: 600,
  roles: {
    'Data & AI Consumer': 0,
    'Data & AI Analyst': 0,
    'Data & AI Power User': 0,
    'Data & AI Expert': 0,
    'Data & AI Owner': 0,
    'Data & AI Steward': 0,
    'Data & AI Custodian': 0,
  },
  questions: [
    // Question 1
    {
      question: 'How often do you use data in your day-to-day work?',
      choices: [
        'Occasionally when reports are given to me.',
        'Regularly, to create reports and insights.',
        'I guide others in using data effectively.',
        'I am deeply involved in the creation and maintenance of data products.',
        'I oversee the quality and usage of data for a specific domain.',
        'I am responsible for data quality and integrity within my domain.',
        'I handle the technical aspects of data maintenance and quality.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Occasionally when reports are given to me.': 'Data & AI Consumer',
        'Regularly, to create reports and insights.': 'Data & AI Analyst',
        'I guide others in using data effectively.': 'Data & AI Power User',
        'I am deeply involved in the creation and maintenance of data products.':
          'Data & AI Expert',
        'I oversee the quality and usage of data for a specific domain.':
          'Data & AI Owner',
        'I am responsible for data quality and integrity within my domain.':
          'Data & AI Steward',
        'I handle the technical aspects of data maintenance and quality.':
          'Data & AI Custodian',
      },
    },
    // Question 2
    {
      question: 'What is your primary responsibility when it comes to data & AI?',
      choices: [
        'Making decisions based on insights provided by others.',
        'Extracting and transforming data for analysis.',
        'Being the go-to person for data-related queries in my department.',
        'Building data & AI products in collaboration with IT.',
        'Ensuring data & AI integrity and protection for a domain.',
        'Guaranteeing data & AI quality within my domain.',
        'Implementing IT features for data & AI systems.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Making decisions based on insights provided by others.': 'Data & AI Consumer',
        'Extracting and transforming data for analysis.': 'Data & AI Analyst',
        'Being the go-to person for data-related queries in my department.':
          'Data & AI Power User',
        'Building data & AI products in collaboration with IT.': 'Data & AI Expert',
        'Ensuring data & AI integrity and protection for a domain.': 'Data & AI Owner',
        'Guaranteeing data & AI quality within my domain.': 'Data & AI Steward',
        'Implementing IT features for data & AI systems.': 'Data & AI Custodian',
      },
    },
    // Question 3
    {
      question:
        'Do you create data models or analytics for self-service within your department?',
      choices: [
        'No, I use data models created by others.',
        'Yes, frequently.',
        'I assist others with these models.',
        'I build tools that enable self-service analytics.',
        'I ensure these models are up to quality standards for my domain.',
        'I ensure the data used in these models is protected and reliable.',
        'I am involved in the technical side of setting up these models.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'No, I use data models created by others.': 'Data & AI Consumer',
        'Yes, frequently.': 'Data & AI Analyst',
        'I assist others with these models.': 'Data & AI Power User',
        'I build tools that enable self-service analytics.': 'Data & AI Expert',
        'I ensure these models are up to quality standards for my domain.':
          'Data & AI Owner',
        'I ensure the data used in these models is protected and reliable.':
          'Data & AI Steward',
        'I am involved in the technical side of setting up these models.':
          'Data & AI Custodian',
      },
    },
    // Question 4
    {
      question:
        'What describes your interaction with IT in relation to data & AI products?',
      choices: [
        'Minimal; I focus more on using the end products of IT.',
        'Occasionally, for specific analysis tasks.',
        'I often collaborate to resolve data issues.',
        'I work closely with IT to develop data products.',
        "I ensure IT's data products meet domain-specific needs.",
        'I work with IT to ensure data integrity.',
        'I am part of the IT team working on data products.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Minimal; I focus more on using the end products of IT.': 'Data & AI Consumer',
        'Occasionally, for specific analysis tasks.': 'Data & AI Analyst',
        'I often collaborate to resolve data issues.': 'Data & AI Power User',
        'I work closely with IT to develop data products.': 'Data & AI Expert',
        "I ensure IT's data products meet domain-specific needs.": 'Data & AI Owner',
        'I work with IT to ensure data integrity.': 'Data & AI Steward',
        'I am part of the IT team working on data products.': 'Data & AI Custodian',
      },
    },

    // Question 5
    {
      question: "How would you describe your role in your organization's data team?",
      choices: [
        'I am an end user of data products.',
        'I am an analyst within the data team.',
        'I am a subject matter expert and support role for data users.',
        'I am a builder and innovator of data solutions.',
        'I own the data & AI strategy for a particular domain.',
        'I steward the quality and compliance of data.',
        'I am a custodian, handling the technical upkeep of data systems.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'I am an end user of data products.': 'Data & AI Consumer',
        'I am an analyst within the data team.': 'Data & AI Analyst',
        'I am a subject matter expert and support role for data users.':
          'Data & AI Power User',
        'I am a builder and innovator of data solutions.': 'Data & AI Expert',
        'I own the data & AI strategy for a particular domain.': 'Data & AI Owner',
        'I steward the quality and compliance of data.': 'Data & AI Steward',
        'I am a custodian, handling the technical upkeep of data systems.':
          'Data & AI Custodian',
      },
    },

    // Question 6
    {
      question: 'If you encountered a data-related issue, what would be your first step?',
      choices: [
        'Report it to the data support team.',
        'Analyze and try to solve the issue.',
        'Provide guidance to others on how to proceed.',
        'Work on a technical solution.',
        'Evaluate the impact on data quality and compliance.',
        'Implement measures to prevent future issues.',
        'Check the technical implementation for faults.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Report it to the data support team.': 'Data & AI Consumer',
        'Analyze and try to solve the issue.': 'Data & AI Analyst',
        'Provide guidance to others on how to proceed.': 'Data & AI Power User',
        'Work on a technical solution.': 'Data & AI Expert',
        'Evaluate the impact on data quality and compliance.': 'Data & AI Owner',
        'Implement measures to prevent future issues.': 'Data & AI Steward',
        'Check the technical implementation for faults.': 'Data & AI Custodian',
      },
    },

    // Question 7
    {
      question: 'Which statement best fits your approach to data governance?',
      choices: [
        'I follow the governance policies set by others.',
        'I analyze data in accordance with governance standards.',
        'I help others understand and apply governance policies.',
        'I contribute to the creation of governance tools.',
        'I am accountable for data governance in my domain.',
        'I am responsible for enforcing governance policies.',
        'I ensure technical adherence to governance standards.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'I follow the governance policies set by others.': 'Data & AI Consumer',
        'I analyze data in accordance with governance standards.': 'Data & AI Analyst',
        'I help others understand and apply governance policies.': 'Data & AI Power User',
        'I contribute to the creation of governance tools.': 'Data & AI Expert',
        'I am accountable for data governance in my domain.': 'Data & AI Owner',
        'I am responsible for enforcing governance policies.': 'Data & AI Steward',
        'I ensure technical adherence to governance standards.': 'Data & AI Custodian',
      },
    },
    //question 8
    {
      question: 'When a new data tool is released, how involved are you in the process?',
      choices: [
        'I am a user of the new tool.',
        "I provide input on the tool's functionality for analysis.",
        'I train others to use the new tool.',
        'I am part of the development team.',
        "I manage the tool's rollout for my domain.",
        'I assess the tool for quality and security risks.',
        'I oversee the technical deployment of the tool.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'I am a user of the new tool.': 'Data & AI Consumer',
        "I provide input on the tool's functionality for analysis.": 'Data & AI Analyst',
        'I train others to use the new tool.': 'Data & AI Power User',
        'I am part of the development team.': 'Data & AI Expert',
        "I manage the tool's rollout for my domain.": 'Data & AI Owner',
        'I assess the tool for quality and security risks.': 'Data & AI Steward',
        'I oversee the technical deployment of the tool.': 'Data & AI Custodian',
      },
    },

    // Question 9
    {
      question: 'What is your level of technical expertise with data & AI systems?',
      choices: [
        'Basic; I know how to use the systems for my needs.',
        'Intermediate; I can perform complex analyses.',
        'Advanced; I can support others in using the systems.',
        'Expert; I build and integrate data systems.',
        'Strategic; I make decisions on system quality and use.',
        'Governance-focused; I ensure systems meet quality standards.',
        'Technical; I manage the backend of these systems.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'Basic; I know how to use the systems for my needs.': 'Data & AI Consumer',
        'Intermediate; I can perform complex analyses.': 'Data & AI Analyst',
        'Advanced; I can support others in using the systems.': 'Data & AI Power User',
        'Expert; I build and integrate data systems.': 'Data & AI Expert',
        'Strategic; I make decisions on system quality and use.': 'Data & AI Owner',
        'Governance-focused; I ensure systems meet quality standards.':
          'Data & AI Steward',
        'Technical; I manage the backend of these systems.': 'Data & AI Custodian',
      },
    },

    // Question 10
    {
      question: 'Which of these best describes your daily interaction with data & AI?',
      choices: [
        'I use data to inform my decisions.',
        'I work with data hands-on for insight generation.',
        'I am the point of contact for data queries within my team.',
        'I design and develop data systems and AI models.',
        "I oversee a domain's data quality and strategy.",
        'I enact data quality and protection measures.',
        'I am involved in the technical maintenance and updates of data systems.',
      ],
      type: 'MCQs',
      correctAnswers: [],
      score: 10,
      answerRoles: {
        'I use data to inform my decisions.': 'Data & AI Consumer',
        'I work with data hands-on for insight generation.': 'Data & AI Analyst',
        'I am the point of contact for data queries within my team.':
          'Data & AI Power User',
        'I design and develop data systems and AI models.': 'Data & AI Expert',
        "I oversee a domain's data quality and strategy.": 'Data & AI Owner',
        'I enact data quality and protection measures.': 'Data & AI Steward',
        'I am involved in the technical maintenance and updates of data systems.':
          'Data & AI Custodian',
      },
    },
  ],
}
